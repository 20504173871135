@tailwind base;
@tailwind components;
@tailwind utilities;

#header {
  background-color: green;
}

body.modal-open {
  overflow: hidden !important;
}

.list-circle {
  list-style-type: circle;
}
